import { createRoot } from "react-dom/client";
import App from "./pages/App";
import { Provider } from "react-redux";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import HomeScreen from "./pages/HomeScreen";
import NotfnDetails from "./pages/NotfnDetails";
import DetailsContextProvider from "./contexts/DetailsContext";
import SelectCountry from "./pages/SelectCountry";
import ViewKCIContextProvider from "./contexts/ViewKCIContext";
import CountryContextProvider from "./contexts/CountriesContext";
import "./index.css";
import "./font.css";
import { ILOFFBootstrap } from "./iloff/ILOFFBootstrap";
import { AuthBootstrap, AuthGateway } from "./auth";
import { store } from "./state/store";
import { EnvironmentName } from "@ilo-ff/apps";

const AppProvider: React.FC = () => {
  return (
    <Provider store={store}>
      <AuthBootstrap
        auth0Domain={process.env.REACT_APP_AUTH_DOMAIN as string}
        auth0ClientId={process.env.REACT_APP_AUTH_CLIENT_ID as string}
      >
        <AuthGateway>
          <ViewKCIContextProvider>
            <DetailsContextProvider>
              <CountryContextProvider>
                <ILOFFBootstrap
                  iloFfUrl={process.env.REACT_APP_ILOFF_URL as string}
                  defaultTitle="Order Communication"
                  environment={process.env.REACT_APP_ILOFF_ENV_NAME as EnvironmentName}
                >
                  <Navigation />
                </ILOFFBootstrap>
              </CountryContextProvider>
            </DetailsContextProvider>
          </ViewKCIContextProvider>
        </AuthGateway>
      </AuthBootstrap>
    </Provider>
  );
};

const Navigation = () => {
  return (
    <Routes>
      <Route index element={<App />} />
      <Route path="/home" element={<HomeScreen />} />
      <Route path="/country" element={<SelectCountry />} />
      <Route path="/details" element={<NotfnDetails />} />
      <Route path="/auth/callback" element={<App />} />
    </Routes>
  );
};

const container = document.querySelector("#root");
const root = createRoot(container!);
root.render(
  <BrowserRouter>
    <AppProvider />
  </BrowserRouter>
);
